(function() {
  function ClipboardManager() {
    const listItemLevelAttribute = "data-list-item-level";
    const ERROR_ALERT_MESSAGE =
      "An error occurred while pasting your language into LeasePilot, please try again";

    this.pasteCounter = 0;
    this.listOutlineMenu = null;
    this.getClipboardDataFromPaste = event => {
      const retObject = {
        textHTML: null,
        textRTF: null,
        fromWord: false,
        type: "text/html",
        noParagraphs: null,
      };

      var clipboardData = (event.originalEvent || event).clipboardData;
      if (typeof clipboardData != "undefined") {
        retObject.textRTF = clipboardData.getData("text/rtf");
        retObject.textHTML = clipboardData.getData("text/html");
      }

      //for debugging only remove b4 commit
      if (window.clipboardHTML) {
        retObject.textHTML = window.clipboardHTML;
      }

      if (window.isDebug) {
        console.log(retObject.textRTF);
        console.log(retObject.textHTML);
      }

      if (
        retObject.textHTML &&
        retObject.textHTML.indexOf("<w:WordDocument>") !== -1
      ) {
        retObject.fromWord = true;
      }

      if (retObject.textRTF) {
        retObject.type = "text/rtf";
      }

      return retObject;
    };

    async function sendRtfToServer($q, ApiService, text) {
      const deferred = $q.defer();
      ApiService.rtfToHtml(text)
        .then(serverData => {
          deferred.resolve(serverData);
        })
        .catch(function() {
          deferred.resolve("");
        });
      return deferred.promise;
    }


    function prepareHTML(html) {
      let result = html;
      
      if (!scopeVar.features.supportSmartQuotes) {
        result = result.replace(/“/g, '"');
        result = result.replace(/”/g, '"');
        result = result.replace(/‘/g, "'");
        result = result.replace(/’/g, "'");
      }

      result = result.replace(/–/g, "-");
      result = result.replace(/—/g, "-");
      result = result.replace(/…/g, "...");
      result = result.replace(/•/g, "*");

      return result;
    };

    async function getHTMLFromRTF(text, $q, ApiService) {
      const deferred = $q.defer();

      if (text.length !== "") {
        let data = await sendRtfToServer($q, ApiService, text);
        if (data === "") {
          deferred.resolve("<Error/>");
          return;
        }
        const decoder = new TextDecoder();
        const answer = decoder.decode(data.data);
        let dummy = document.createElement("dummy");
        let retDummy = document.createElement("dummy");
        dummy.innerHTML = prepareHTML(answer);

        if (
          dummy.firstElementChild &&
          dummy.firstElementChild.tagName &&
          dummy.firstElementChild.tagName !== "TEXTAREA"
        ) {
          deferred.resolve("<Error/>");
        } else {
          dummy.innerHTML = dummy.firstElementChild.innerText;

          if (
            dummy &&
            dummy.firstElementChild &&
            dummy.firstElementChild.tagName === "DIV"
          ) {
            while (dummy.children.length !== 0) {
              let node = dummy.children[0];
              node.remove();
              if (node.tagName === "DIV") {
                while (node.children.length !== 0) {
                  retDummy.appendChild(node.children[0]);
                }
              } else {
                retDummy.appendChild(node);
              }
            }
            deferred.resolve(retDummy.innerHTML);
          } else {
            deferred.resolve(dummy.innerHTML);
          }
        }
      } else {
        deferred.resolve("");
      }

      deferred.resolve();

      return deferred.promise;
    }

    this.showPastingDialog = $scope => {
      $scope.loadingPaste = true;
      $scope.safeApply();
    };

    this.hidePastingDialog = $scope => {
      $scope.loadingPaste = false;
      $scope.safeApply();
    };

    function markWordListItems(dummy) {
      let foundListItems = false;
      const getLevelRegex = new RegExp("mso-list:.*level(.*) ");
      dummy.querySelectorAll('p[style*="mso-list"]').forEach(node => {
        const getLevel = getLevelRegex.exec(node.getAttribute("style"));
        if (getLevel && getLevel[1] && parseInt(getLevel[1])) {
          // word start lists levels from 1 and we start form 0 thats why we do:`getLevel[1]) - 1`
          node.setAttribute("data-list-item-level", parseInt(getLevel[1]) - 1);
          foundListItems = true;
        }
      });

      dummy.childNodes.forEach(node => {
        if (
          node &&
          node.querySelector &&
          node.querySelector("[data-list-item-level]")
        ) {
          node.setAttribute(
            "data-list-item-level",
            node
              .querySelector("[data-list-item-level]")
              .getAttribute("data-list-item-level")
          );
        }
      });

      dummy.querySelectorAll("[data-list-item-level]").forEach(el => {
        if (!el.parentElement.closest("[data-list-item-level]")) {
          let p = document.createElement("p");
          p.innerText = `!!SET_LEVEL_${el.getAttribute(
            "data-list-item-level"
          )}!!`;
          el.parentElement.insertBefore(p, el);
        }
      });

      dummy.querySelectorAll('span[style*="mso-tab-count"]').forEach(el => {
        el.setAttribute("style", 'font:7.0pt "Times New Roman"');
        el.innerHTML = "!!TAB!!";
      });

      return foundListItems;
    }

    function updateListItemsSpacesFromWord(stringHtml) {
      const element = document.createElement("dummy");
      element.innerHTML = stringHtml;
      const spanList = element.querySelectorAll('span[style*="7pt"]');
      const containerArray = [];
      let index = 0;
      let current = null;
      while (index < spanList.length) {
        if (spanList[index].style.fontSize == "7pt") {
          if (spanList[index].textContent == "!!TAB!!") {
            spanList[index].innerHTML = "&nbsp;";
          }
          current = spanList[index].parentElement;
          if (containerArray.indexOf(current) === -1) {
            containerArray.push(current);
          }
        }
        index++;
      }

      containerArray.forEach(el => {
        const numberElement = el.children[0];
        const spacingElement = el.children[1];
        let found = false;
        if (
          numberElement.textContent.trim().length !== 0 &&
          spacingElement.textContent.trim().length == 0 &&
          spacingElement.style.fontSize == "7pt"
        ) {
          while (
            spacingElement.nextElementSibling &&
            spacingElement.nextElementSibling.style.fontSize == "7pt" &&
            spacingElement.nextElementSibling.textContent.trim().length == 0
          ) {
            spacingElement.nextElementSibling.remove();
            found = true;
          }

          if (
            numberElement.textContent.trim().length !== 0 &&
            spacingElement.textContent.trim().length === 0 &&
            spacingElement.style.fontSize == "7pt" &&
            spacingElement.nextElementSibling &&
            spacingElement.nextElementSibling.textContent.trim() !== 0 &&
            spacingElement.nextElementSibling.fontSize !== "7pt"
          ) {
            found = true;
          }

          if (found) {
            numberElement.classList.add("word-numbers");
            spacingElement.classList.add("word-numbers");
          }
        }
      });

      return element.innerHTML;
    }

    this.init = (
      _$scope,
      _$compile,
      _$q,
      _LeaseEditorService,
      _ApiService,
      _$timeout
    ) => {
      this.$scope = _$scope;
      this.$compile = _$compile;
      this.$q = _$q;
      this.LeaseEditorService = _LeaseEditorService;
      this.ApiService = _ApiService;
      this.$timeout = _$timeout;
    };

    this.insertClause = async (html, data) => {
      this.$scope.pauseSearch();

      if (!html && !data) {
        html = document.getElementById("htmlTempInput").value;
        data = document.getElementById("dataTempInput").value;
      }

      data = data.replace(/<w:hyperlink.*?>/g, "");
      data = data.replace(/<\/w:hyperlink>/g, "");

      
      // Imitate the behavior of paste
      if (contentEditableManager.isCaretAtEndContainer()) {
        const clipboardTextAndType = {
          textHTML: html,
          textRTF: data,
          fromWord: true,
          type: "text/rtf",
          noParagraphs: null,
        };
        
        this.handlePaste(
          null,
          this.$scope,
          this.$compile,
          this.$q,
          this.LeaseEditorService,
          this.ApiService,
          this.$timeout,
          clipboardTextAndType
        );
      } else {
        const sel = rangy.getSelection();
        const anchorNode = sel.anchorNode;
        const contentEditable = this.LeaseEditorService.findClosestContentEditable(anchorNode);
        $(contentEditable).trigger('lp:clausebook:insert', { html, data });;
      }
    };

    this.insertText = async (text) => {
      this.$scope.pauseSearch();

      // Imitate the behavior of paste
      if (contentEditableManager.isCaretAtEndContainer()) {
        const clipboardTextAndType = {
          textHTML: text,
          textRTF: text,
          fromWord: true,
          type: "text/plain",
          noParagraphs: null,
        };
        
        this.handlePaste(
          null,
          this.$scope,
          this.$compile,
          this.$q,
          this.LeaseEditorService,
          this.ApiService,
          this.$timeout,
          clipboardTextAndType
        );
      } else {
        const sel = rangy.getSelection();
        const anchorNode = sel.anchorNode;
        const contentEditable = this.LeaseEditorService.findClosestContentEditable(anchorNode);
        $(contentEditable).trigger('lp:text:insert', { text });;
      }
    };

    this.handlePaste = async (
      event,
      $scope,
      $compile,
      $q,
      LeaseEditorService,
      ApiService,
      $timeout,
      clipboardTextAndType
    ) => {
      if (!clipboardTextAndType) {
        clipboardTextAndType = this.getClipboardDataFromPaste(event);
        this.$scope.PasteMessage = "Pasting";
      } else {
        this.$scope.PasteMessage = "Inserting clause";
      }

      clipboardTextAndType.textHTML = clipboardTextAndType.textHTML
        .replace(/\n/g, " ")
        .replace(/\r/g, "");

      if (!clipboardTextAndType.textHTML && !clipboardTextAndType.textRTF) {
        alert(
          `The current browser does not support 'paste' button functionality. \r\n Please use Ctrl + V key combination`
        );
        return;
      }

      if (clipboardTextAndType.textRTF === "") {
        alert(
          `It seems like you are trying to paste in a selection from a protected document and we are unable to access your clipboard. Please turn off protected view in Word and try again.`
        );
        return;
      }

      clipboardManager.showPastingDialog($scope);

      if (clipboardTextAndType.textHTML && clipboardTextAndType.fromWord) {
        const dummyElement = document.createElement("dummy");
        dummyElement.innerHTML = clipboardTextAndType.textHTML;
        markWordListItems(dummyElement);

        const paragraphs = dummyElement.querySelectorAll("p, h1, h2, h3, h4, h5, h6");
        if (paragraphs.length === 0) {
          clipboardTextAndType.noParagraphs = true;
        } else {
          clipboardTextAndType.noParagraphs = false;
        }
        
        dummyElement.querySelectorAll('[style*="display:none;"]').forEach(e => {
          e.textContent = "";
        });

        clipboardTextAndType.htmlFromHtml = await getHTMLFromRTF(
          dummyElement.innerHTML,
          $q,
          ApiService
        );

        if (window.isDebug) {
          console.log(clipboardTextAndType.htmlFromHtml);
        }

        clipboardTextAndType.htmlFromHtml = updateListItemsSpacesFromWord(
          clipboardTextAndType.htmlFromHtml
        );
        clipboardTextAndType.htmlFromHtml = clipboardTextAndType.htmlFromHtml.replace(
          /\n/g,
          " "
        );
      }

      if (clipboardTextAndType.textRTF && clipboardTextAndType.fromWord) {
        clipboardTextAndType.htmlFromRtf = await getHTMLFromRTF(
          clipboardTextAndType.textRTF,
          $q,
          ApiService
        );

        if (!clipboardTextAndType.htmlFromRtf) {
          this.hidePastingDialog($scope);
          alert(
            `Oops, something went wrong during the paste process. Please try again and send an email to support@leasepilot.co if the issue persists. `
          );
          return;
        }

        //debugging only remove b4 commit
        if (window.testTingHtmlFromRTF) {
          clipboardTextAndType.htmlFromRtf = window.testTingHtmlFromRTF;
        }

        clipboardTextAndType.htmlFromRtf = clipboardTextAndType.htmlFromRtf.replace(
          /\n/g,
          " "
        );

        if (window.isDebug) {
          console.log(clipboardTextAndType.htmlFromRtf);
        }
      }

      if (
        clipboardTextAndType.htmlFromHtml == "<Error/>" ||
        clipboardTextAndType.htmlFromRtf == "<Error/>"
      ) {
        this.hidePastingDialog($scope);
        alert(ERROR_ALERT_MESSAGE);
        return;
      }

      let clipData = null;

      if (clipboardTextAndType.fromWord) {
        clipData = new ClipboardData(
          clipboardTextAndType.type,
          clipboardTextAndType.htmlFromRtf,
          clipboardTextAndType.textRTF,
          $compile,
          $timeout,
          $scope,
          LeaseEditorService
        );
      } else if (!clipboardTextAndType.fromWord) {
        clipData = new ClipboardData(
          "text/html",
          clipboardTextAndType.htmlFromHtml,
          clipboardTextAndType.textHTML,
          $compile,
          $timeout,
          $scope,
          LeaseEditorService
        );
      }

      clipData.source = clipboardTextAndType;

      if (clipData.processTextFailed) {
        this.hidePastingDialog($scope);
        alert(ERROR_ALERT_MESSAGE);
      } else {
        this.pasteWithListItems(
          clipData,
          $scope,
          $compile,
          $q,
          LeaseEditorService
        );
      }
    };

    function pasteTextOnly(clipData, $scope, $compile, $q, LeaseEditorService) {
      let html = clipData.pastedElements.innerHTML;

      if ($scope.$root.adminMode) {
        html =
          "<span class='paste-elements override-color'>" + html + "</span>";
      } else {
        html = "<span class='paste-elements'>" + html + "</span>";
      }

      let sel = rangy.getSelection();

      if (rangy.getSelection && sel.rangeCount) {
        let range = sel.getRangeAt(0);
        var htmlObj = $(html);

        if ($scope.$root.adminMode) {
          $(htmlObj)
            .find("p")
            .addClass("override-color");
        }

        function paste() {
          sel = rangy.getSelection();
          range = sel.getRangeAt(0);
          var node = htmlObj[0];
          range.insertNode(node);
          let span = document.querySelector(".paste-elements");
          span.classList.remove("paste-elements");
          range.collapseAfter(node);
          range.select();
          $scope.loadingPaste = false;
          $scope.safeApply();
          return node;
        }

        if (LeaseEditorService.isSelecting()) {
          // Delete the text the user selected before inserting the pasted text
          $scope.$root.deleteSelection(paste, true);
        } else {
          let node = paste();

          $scope.createBulk([node.closest("[section-id]")]);
        }
      }
    }

    async function waitForAllFreeTextCompile(element) {
      const waitForFreeTextCompile = function(resolve, reject) {
        let testCounter = 0;

        const isReady = element => {
          return (
            element.querySelectorAll(`[free-text]`).length ===
            element.querySelectorAll(`[free-text] .EOCE`).length
          );
        };

        const reTest = () => {
          testCounter++;
          if (isReady(element)) {
            resolve(element);
          } else if (testCounter < 200) {
            setTimeout(reTest, 200);
          } else {
            reject(element);
          }
        };

        if (isReady(element)) {
          resolve(element);
        } else {
          setTimeout(reTest, 20);
        }
      };
      return new Promise(waitForFreeTextCompile);
    }

    function createSubParagraphListItemObject(outline, afterFreeText, $scope) {
      return {
        outline: outline,
        level: "sub-paragraph",
        headerFreeText: $scope.getNextFreeTextValue(),
        bodyFreeText: $scope.getNextFreeTextValue(),
        isNewListItem: true,
        singleFT: true,
        fromUI: false,
        afterFreeText: afterFreeText
      };
    }

    function copyItemsForTableTemplate(
      source,
      destination,
      listItemObjectsArray,
      outline,
      $scope,
      restyleElements
    ) {
      let srcTDList = source.querySelectorAll("td");
      let dstTDList = destination.querySelectorAll("td");
      let newAfterFreeText = null;
      let tdIndex = 0;

      while (tdIndex < dstTDList.length) {
        let paragraphIndex = 0;
        let paragraphList = srcTDList[tdIndex].querySelectorAll(
          "p,h1,h2,h3,h4,h5,h6"
        );

        while (paragraphIndex < paragraphList.length) {
          let copyElementsTo = null;
          if (paragraphIndex === 0) {
            copyElementsTo = dstTDList[tdIndex].querySelector("[free-text]");
          } else {
            newAfterFreeText = dstTDList[tdIndex]
              .querySelectorAll("[free-text]")
              .lastItem()
              .getAttribute("free-text");
            let newListItemObject = createSubParagraphListItemObject(
              outline,
              newAfterFreeText,
              $scope
            );
            listItemObjectsArray.push(newListItemObject);
            let isImage = !!paragraphList[paragraphIndex].querySelector("img");
            let innerTemplate = listItemsManager.createListItemFromTemplate(
              "sub-paragraph",
              null,
              null,
              newListItemObject,
              false,
              isImage
            );

            while (innerTemplate.childNodes.length !== 0) {
              dstTDList[tdIndex].appendChild(innerTemplate.childNodes[0]);
            }

            copyElementsTo = dstTDList[tdIndex]
              .querySelectorAll("[free-text]")
              .lastItem();

            let restyleObject = getRestyleObjectFromParagraph(
              paragraphList[paragraphIndex],
              copyElementsTo.getAttribute("free-text")
            );
            restyleElements.push(restyleObject);
          }

          if (copyElementsTo) {
            while (paragraphList[paragraphIndex].childNodes.length !== 0) {
              copyElementsTo.appendChild(
                paragraphList[paragraphIndex].childNodes[0]
              );
            }
          }

          paragraphIndex++;
        }
        tdIndex++;
      }
    }
    copyItemsForTableTemplate = copyItemsForTableTemplate.bind(this);

    function getRestyleObjectFromParagraph(element, pid) {
      if (
        !element.getAttribute("data-list-item-level") &&
        element.querySelector("ul")
      ) {
        let originalTextIndent = element.style["text-indent"];
        let originalMarginLeft = element.style["margin-left"];

        if (originalMarginLeft && originalTextIndent) {
          let tempTextIndentCalc = parseInt(
            originalTextIndent.replace("pt", "").replace("px", "")
          );
          let tempMarginLeftCalc = parseInt(
            originalMarginLeft.replace("pt", "").replace("px", "")
          );

          if (tempTextIndentCalc && tempTextIndentCalc < 0) {
            let marginLeftCalc = tempMarginLeftCalc + tempTextIndentCalc;
            element.style["text-indent"] = "0pt";
            element.style["margin-left"] = marginLeftCalc + "pt";
          }
        }
      }

      let textIndentCalc = parseInt(element.style["text-indent"])
        ? parseInt(
            element.style["text-indent"].replace("pt", "").replace("px", "")
          ) / window.CONSTS.STEP_SIZE
        : 0;

      let paragraphIndentCalc = parseInt(element.style["margin-left"])
        ? parseInt(
            element.style["margin-left"].replace("pt", "").replace("px", "")
          ) / window.CONSTS.STEP_SIZE
        : 0;

      let restyleObject = {
        paragraphId: pid,
        textAlign: element.style["text-align"],
        textIndent: textIndentCalc,
        paragraphIndent: paragraphIndentCalc,
        marginTop: element.style["margin-top"],
        marginBottom: element.style["margin-bottom"],
        fontSize: element.style["font-size"],
        lineHeight: element.style["line-height"]
      };

      return restyleObject;
    }

    window.getRestyleObjectFromParagraph = getRestyleObjectFromParagraph;

    this.saveUndoDataForException = ($scope, rtfData) => {
      return {
        currentUndoIndex: $scope.histIndex,
        currentListItems: JSON.parse(JSON.stringify($scope.listItems)),
        currentReStyle: JSON.parse(JSON.stringify($scope.lease.restyle)),
        currentListItemUndoTracking: JSON.parse(
          JSON.stringify($scope.listItemUndoTracking)
        ),
        currentListItemObjects: JSON.parse(
          JSON.stringify($scope.listItemObjects)
        ),
        currentPageLayers: $scope.lease.layers ? [...$scope.lease.layers] : [],
        currentHistBatcher: $scope.histBatcher ? $scope.histBatcher : {},
        currentHistStackLength: $scope.histStack ? $scope.histStack.length : 0,
        pasteCounter: this.pasteCounter,
        rtfData: rtfData
      };
    };

    this.undoOnError = (error, undoData, element, $scope) => {
      element.remove();
      this.hidePastingDialog($scope);
      Raven.setExtraContext({ error: error, undoData: undoData });
      Raven.captureMessage("Error pasting ");
      alert(ERROR_ALERT_MESSAGE);
    };

    function updateListItemNumberFromRtf(data) {
      if (
        data.source.fromWord &&
        data.source.htmlFromHtml &&
        data.source.textRTF
      ) {
        const el = document.createElement("dummy");
        el.innerHTML = data.source.htmlFromHtml;
        data.cleanHtmlFromRtf(el);

        const rtfTDs = data.pastedElements.querySelectorAll("td", true);
        const htmlTDs = el.querySelectorAll("td", true);

        if (rtfTDs.length === htmlTDs.length) {
          for (let index = 0; index < rtfTDs.length; index++) {
            if (
              rtfTDs[index].children.length === 0 &&
              htmlTDs[index].children.length !== 0
            ) {
              rtfTDs[index].innerHTML = htmlTDs[index].innerHTML;
            }
          }
        }

        const rtfParagraphs = data.pastedElements.querySelectorAll("p", true);
        const htmlParagraphs = el.querySelectorAll("p", true);

        let rtfItem;
        let htmlItem;

        if (rtfParagraphs.length === htmlParagraphs.length) {
          let scanIndex = 0;
          while (scanIndex < rtfParagraphs.length) {
            rtfItem = rtfParagraphs[scanIndex];
            htmlItem = htmlParagraphs[scanIndex];

            if (
              rtfItem.textContent.trim().length === 0 &&
              htmlItem.textContent.trim().length !== 0 &&
              htmlItem.parentElement.tagName === "TD" &&
              rtfItem.parentElement.tagName === "TD"
            ) {
              rtfItem.innerHTML = htmlItem.innerHTML;
              data.updateFonts(rtfItem);
            }

            if (
              !rtfItem.querySelector('[style*="7pt"]') &&
              htmlItem.querySelector('[style*="7pt"]')
            ) {
              if (htmlItem.querySelector(".word-numbers")) {
                let numberEl = htmlItem.children[0];
                let spaceEl = htmlItem.children[1];
                let rtfFirstItem = rtfItem.firstElementChild;
                let anchor = rtfItem.firstElementChild;

                if (
                  numberEl && 
                  rtfFirstItem && 
                  spaceEl && 
                  anchor &&
                  numberEl.textContent.trim() !== rtfFirstItem.textContent.trim() &&
                  spaceEl.style.fontSize === "7pt"
                ) {
                  rtfItem.insertBefore(numberEl.cloneNode(true), anchor);
                  anchor.previousElementSibling.style.fontFamily =
                    anchor.style.fontFamily;
                  rtfItem.insertBefore(spaceEl.cloneNode(true), anchor);
                  anchor.previousElementSibling.style.fontFamily =
                    anchor.style.fontFamily;
                }
              }
            } else if (
              rtfItem.querySelector('[style*="7pt"]') &&
              htmlItem.querySelector('[style*="7pt"]')
            ) {
              if (
                rtfItem.firstElementChild.textContent.trim().length === 0 &&
                rtfItem.firstElementChild.style.fontSize === "7pt" &&
                htmlItem.firstElementChild.textContent.trim().length !== 0 &&
                htmlItem.children[1] &&
                htmlItem.children[1].textContent.trim().length === 0 &&
                htmlItem.children[1].style.fontSize === "7pt"
              ) {
                rtfItem.insertBefore(
                  htmlItem.firstElementChild.cloneNode(true),
                  rtfItem.firstElementChild
                );

                if (rtfItem.children[2]) {
                  rtfItem.children[0].style.fontFamily =
                    rtfItem.children[2].style.fontFamily;
                }
              }
            }
            scanIndex++;
          }
        }
      }
    }

    this.continuePasteWithOutline = async newOutlineName => {
      if (newOutlineName) {
        this.hideOutlinePicker();
      }

      let data = this.savedArguments.data;
      let $scope = this.savedArguments.$scope;
      let $compile = this.savedArguments.$compile;
      let $q = this.savedArguments.$q;
      let LeaseEditorService = this.savedArguments.LeaseEditorService;
      let freeTextArray;
      this.pasteCounter++;
      const forceAllParagraph = this.savedArguments.forceAllParagraph;

      updateListItemNumberFromRtf(data);

      clipboardManager.showPastingDialog($scope);

      let currentUndoIndex = $scope.histIndex;
      let currentListItems = JSON.parse(JSON.stringify($scope.listItems));
      const currentReStyle = JSON.parse(JSON.stringify($scope.lease.restyle));
      const currentListItemUndoTracking = JSON.parse(
        JSON.stringify($scope.listItemUndoTracking)
      );
      const currentListItemObjects = JSON.parse(
        JSON.stringify($scope.listItemObjects)
      );
      const currentPageLayers = $scope.lease.layers
        ? [...$scope.lease.layers]
        : [];
      let restyleElements = [];
      let outlineTemplate = null;
      let outlineObject = null;
      let listItemUndoTracking = {};
      let listItemObjects = [];

      let currentNode;

      if ($scope.currentlyEditedNode instanceof jQuery) {
        currentNode = $scope.currentlyEditedNode.get(0);
      } else {
        currentNode = $scope.currentlyEditedNode;
      }

      let anchor = currentNode.getTopAnchor();
      let outline = anchor.getNodeOutline();

      let workElement = null;
      let lastTemplate = null;
      let listItemObjectsArray = [];
      let itemLevel = null;
      let nextItemAnchor = null;
      let levelInfo = null;
      let freeTextsInTemplate = null;
      let pageLayers = [];
      let outlineEndElement = null;
      const boxDiv = document.createElement("div");

      const elementsToInsert = document.createElement("ins");
      elementsToInsert.classList.add(
        "diff-item--accepted",
        "diff-item",
        "pasted-element"
      );

      if ($scope.$root.adminMode) {
        elementsToInsert.classList.add(
          "diff-item--accepted",
          "diff-item",
          "pasted-element",
          "paste-elements",
          "override-color"
        );
      }

      elementsToInsert.setAttribute("data-paste-id", this.pasteCounter);

      const errorUndoData = this.saveUndoDataForException(
        $scope,
        data.originalRTF
      );
      try {
        if (newOutlineName) {
          let outlineId = pageLayersManager.getNextLayerId();
          let anchorPid = anchor.getAttribute("pid");
          let index = anchor.getNodeOccurrenceIndexByAttribute("pid");
          outlineTemplate = pageLayersManager.createOutlineElement(
            newOutlineName,
            outlineId
          );
          outlineObject = pageLayersManager.createOutlineObject(
            anchorPid,
            newOutlineName,
            outline,
            index
          );
          pageLayers.push(outlineObject);
          while (outlineTemplate.children.length !== 0) {
            elementsToInsert.appendChild(outlineTemplate.children[0]);
          }
          outlineEndElement = elementsToInsert.querySelector("outline-end");
          outline = newOutlineName;
        }

        while (data.pastedElements.children.length !== 0) {
          workElement = data.pastedElements.children[0];
          workElement.remove();
          itemLevel = workElement.hasAttribute(listItemLevelAttribute)
            ? workElement.getAttribute(listItemLevelAttribute)
            : "999";

          if (forceAllParagraph) {
            itemLevel = "999";
          }

          let isImage = !!workElement.querySelector("img");
          let listItemLevel = listItemsManager.listLevelsMap[outline]
            ? listItemsManager.listLevelsMap[outline][itemLevel]
              ? listItemsManager.listLevelsMap[outline][itemLevel].level
              : "paragraph"
            : "paragraph";

          if (listItemLevel === "paragraph") {
            listItemLevel = listItemsManager.getOutlineParagraphLevel(outline);
          }

          if (workElement.hasAttribute("data-is-bullet")) {
            listItemLevel = "ul-paragraph";
            workElement
              .querySelectorAll(".word-numbers")
              .forEach(node => node.remove());
          }

          let newListItemObject = {
            outline: outline,
            level: listItemLevel,
            headerFreeText: $scope.getNextFreeTextValue(),
            bodyFreeText: $scope.getNextFreeTextValue(),
            isNewListItem: true,
            singleFT: true,
            fromUI: listItemObjectsArray.length === 0,
            afterFreeText: nextItemAnchor
              ? nextItemAnchor
              : anchor.getAttribute("pid")
          };

          if (newOutlineName && listItemObjectsArray.length === 0) {
            newListItemObject.fromUI = false;
            newListItemObject.afterFreeText = outlineObject.id;
          }

          if (listItemObjectsArray.length === 0) {
            listItemsManager.locateNewListItemAnchor(newListItemObject);
          }

          levelInfo = listItemsManager.getLevelInfoByName(
            newListItemObject.level
          );
          listItemObjectsArray.push(newListItemObject);

          lastTemplate = listItemsManager.createListItemFromTemplate(
            levelInfo
              ? levelInfo.level
              : listItemLevel === "ul-paragraph"
              ? listItemLevel
              : "paragraph",
            levelInfo ? levelInfo.format : null,
            levelInfo ? levelInfo.name : null,
            newListItemObject,
            false,
            isImage
          );

          nextItemAnchor = lastTemplate
            .querySelectorAll("[pid]")
            .lastItem()
            .getAttribute("pid");

          freeTextsInTemplate = lastTemplate.querySelectorAll("[free-text]");

          if (lastTemplate.firstElementChild.tagName === "TABLE") {
            //check number of cells
            if (
              lastTemplate.querySelectorAll("TD").length !==
              workElement.querySelectorAll("TD").length
            ) {
              lastTemplate = listItemsManager.createListItemFromTemplate(
                "paragraph",
                null,
                null,
                newListItemObject,
                false,
                isImage
              );
              freeTextsInTemplate = lastTemplate.querySelectorAll(
                "[free-text]"
              );
              newListItemObject.level = "paragraph";
              levelInfo = null;
            }
          }

          if (levelInfo) {
            workElement.querySelectorAll(".word-numbers").forEach(node => {
              node.remove();
            });
          }

          if (lastTemplate.firstElementChild.tagName === "TABLE") {
            copyItemsForTableTemplate(
              workElement,
              lastTemplate,
              listItemObjectsArray,
              outline,
              $scope,
              restyleElements
            );
          } else {
            for (
              let freeTextIndex = 0;
              freeTextIndex < freeTextsInTemplate.length;
              freeTextIndex++
            ) {
              let currentWorkElement = null;
              if (freeTextIndex === 0) {
                currentWorkElement = workElement;
              } else {
                if (
                  data.pastedElements.children[0] &&
                  data.pastedElements.children[0].tagName &&
                  freeTextsInTemplate[freeTextIndex].closest(
                    data.pastedElements.children[0].tagName
                  )
                ) {
                  currentWorkElement = data.pastedElements.children[0];
                  currentWorkElement.remove();
                }
              }

              if (
                currentWorkElement &&
                currentWorkElement.tagName === "TABLE" &&
                !levelInfo
              ) {
                while (
                  freeTextsInTemplate[freeTextIndex].childNodes.length !== 0
                ) {
                  freeTextsInTemplate[freeTextIndex].childNodes[0].remove();
                }
                
                freeTextsInTemplate[freeTextIndex].appendChild(
                  currentWorkElement
                );
              } else {
                while (
                  freeTextsInTemplate[freeTextIndex].childNodes.length !== 0
                ) {
                  freeTextsInTemplate[freeTextIndex].childNodes[0].remove();
                }

                if (currentWorkElement.getAttribute("data-is-bullet")) {
                  currentWorkElement.querySelectorAll("li").forEach(li => {
                    freeTextsInTemplate[freeTextIndex].appendChild(li);
                  });
                } else if (currentWorkElement && currentWorkElement.tagName === "TABLE") {
                  freeTextsInTemplate[freeTextIndex].appendChild(currentWorkElement);
                } else {
                  while (
                    currentWorkElement &&
                    currentWorkElement.childNodes.length !== 0
                  ) {
                    freeTextsInTemplate[freeTextIndex].appendChild(
                      currentWorkElement.childNodes[0]
                    );
                  }
                }

                if (
                  (currentWorkElement && currentWorkElement.tagName === "P") ||
                  (freeTextsInTemplate[freeTextIndex].parentElement &&
                    freeTextsInTemplate[freeTextIndex].parentElement.tagName ===
                      "P")
                ) {
                  let restyleObject = getRestyleObjectFromParagraph(
                    currentWorkElement,
                    freeTextsInTemplate[freeTextIndex].getAttribute("free-text")
                  );

                  if (this.savedArguments.forceAllParagraph == false) {
                    delete restyleObject.fontSize;
                  }

                  freeTextsInTemplate[freeTextIndex]
                    .querySelectorAll(`span[style*="font-size"]`)
                    .forEach(el => {
                      el.style.fontSize = "";
                    });

                  if (
                    $scope.defaultFontData &&
                    $scope.defaultFontData.defaultFontFamily
                  ) {
                    freeTextsInTemplate[freeTextIndex]
                      .querySelectorAll(`[style*="font-family"]`)
                      .forEach(el => {
                        el.style.fontFamily =
                          $scope.defaultFontData.defaultFontFamily;
                      });
                  }

                  restyleElements.push(restyleObject);
                }
              }
            }
          }

          let myListItemId = $scope.nextUnusedListItemId;
          $scope.nextUnusedListItemId++;
          while (lastTemplate.childNodes.length !== 0) {
            if (lastTemplate.childNodes[0].setAttribute) {
              lastTemplate.childNodes[0].setAttribute(
                "inserted-list-item-id",
                myListItemId
              );

              let currentListItemObject =
                listItemObjectsArray[listItemObjectsArray.length - 1];

              if (currentListItemObject) {
                listItemUndoTracking[
                  `${currentListItemObject.headerFreeText}-${currentListItemObject.bodyFreeText}`
                ] = myListItemId;
                listItemObjects[
                  `${currentListItemObject.headerFreeText}-${currentListItemObject.bodyFreeText}`
                ] = currentListItemObject;
              }

              lastTemplate.childNodes[0].classList.add(
                "lp-show-element",
                "lp-if-ignore",
                "inserted-list-item"
              );
            }
            if (outlineEndElement) {
              elementsToInsert.insertBefore(
                lastTemplate.childNodes[0],
                outlineEndElement
              );
            } else {
              elementsToInsert.appendChild(lastTemplate.childNodes[0]);
            }
          }
        }

        if (outlineObject) {
          anchor = document.querySelectorWithInstance(
            '[pid="' + outlineObject.anchor + '"]',
            listItemObjectsArray[0].index
          );
        }

        // clean restyle from none p elements
        restyleElements = restyleElements.filter(item => {
          let styledElement = elementsToInsert.querySelector(
            `[pid="${item.paragraphId}"]`
          );
          let listFormatElement = styledElement.querySelector("[format]");
          let format = listFormatElement
            ? listFormatElement.getAttribute("format")
            : null;
          if (format) {
            return false;
          } else if (!format) {
            return true;
          } else if (styledElement.tagName === "P") {
            return true;
          } else {
            return false;
          }
        });

        elementsToInsert.removeWhitespace();
        elementsToInsert.fixSubSupDecorations();

        boxDiv.appendChild(elementsToInsert);
        boxDiv.classList.add("new-list-item-container");

        // Fix ul styles..
        const ulElements = boxDiv.querySelectorAll("ul");
        ulElements.forEach(ul => {
          let ulContainer = ul.getTopAnchor();
          let pid = ulContainer.getAttribute("pid");
          for (let i = 0; i < restyleElements.length; ++i) {
            let p = restyleElements[i];
            if (p.paragraphId == pid) {
              p.textIndent = 0;
            }
          }
        });

        // handle first block as inline text Start
        // only when we are not at an empty paragraph and pasting list item
        const freeTexts = boxDiv.querySelectorAll("[free-text]");
        const firstFreeText = freeTexts[0];
        const firstContainer = firstFreeText.getTopAnchor();
        const tableElementInFreeText = firstFreeText.querySelector("table");
        const imageElementInFreeText = firstFreeText.querySelector("img");
        const bulletElementInFreeText = firstFreeText.querySelector("ul");
        const pageBreakElementInFreeText = firstFreeText.querySelector("br");
        const sel = rangy.getSelection();
        const blockData = sel.anchorNode.getBlockData();
        if (
          freeTexts.length === 1 &&
          this.savedArguments.data.source.noParagraphs && 
          !tableElementInFreeText &&
          !imageElementInFreeText &&
          !pageBreakElementInFreeText &&
          !bulletElementInFreeText
        ) {
          while (firstContainer.previousElementSibling) {
            firstContainer.previousElementSibling.remove();
          }

          firstContainer.remove();
          firstFreeText.removeAttribute("free-text");
          contentEditableManager.insertAtCaret(firstFreeText);

          if (firstContainer.hasAttribute("inserted-list-item-id")) {
            if (listItemObjectsArray[1]) {
              listItemObjectsArray[1].afterFreeText =
                listItemObjectsArray[0].afterFreeText;
            }
            listItemObjectsArray.splice(0, 1);
            restyleElements.splice(0, 1);
          }
        } else {
          if (LeaseEditorService.isSelecting()) {
            const sel = rangy.getSelection();
  
            if (sel.isBackward()) {
              anchor = sel.anchorNode.getTopAnchor();
            } else {
              anchor = sel.focusNode.getTopAnchor();
            }
          }
  
          let anchorIndex = anchor.getNodeOccurrenceIndexByAttribute("pid");
          if (listItemObjectsArray && listItemObjectsArray[0]) {
            listItemObjectsArray[0].index = anchorIndex;
          }
  
          if (elementsToInsert.childNodes.length > 0) {
            anchor.parentElement.insertBefore(boxDiv, anchor.nextElementSibling);
            $compile(elementsToInsert)($scope);
            // $scope.compileVisibleFreeTexts();
            await waitForAllFreeTextCompile(elementsToInsert);
  
            if (leaseIfManager.isInFormManager) {
              elementsToInsert.querySelectorAll("[section-id]").forEach(el => {
                el.setAttribute("override", true);
                const EOCEElement = el.querySelector(".EOCE");
                const SOCEElement = el.querySelector(".SOCE");
                if (EOCEElement) {
                  const span = document.createElement("span");
                  span.setAttribute(
                    "style",
                    "color: rgb(0, 156, 33); white-space: pre-wrap;"
                  );
                  EOCEElement.parentElement.insertBefore(span, EOCEElement);
                  while (SOCEElement.nextElementSibling !== span) {
                    span.appendChild(SOCEElement.nextElementSibling);
                  }
                }
              });
            }
          }
        }
      } catch (e) {
        this.undoOnError(e, errorUndoData, boxDiv, $scope);
        return;
      }

      let redo = function(
        listItemObjectsArray,
        pageLayers,
        listItemUndoTracking,
        listItemObjects,
        $scope
      ) {
        return function() {
          const deferred = $q.defer();
          elementsToInsert.classList.add(
            "diff-item--accepted",
            "lp-show-element"
          );
          elementsToInsert.classList.remove(
            "diff-item--rejected",
            "lp-hide-element"
          );
          elementsToInsert.fixEmptyParagraphs();
          restyleElements.forEach(item => {
            $scope.lease.restyle[item.paragraphId] = item;
          });
          if (!$scope.lease.layers) {
            $scope.lease.layers = [];
          }

          Object.keys(listItemUndoTracking).forEach(key => {
            $scope.listItemUndoTracking[key] = listItemUndoTracking[key];
          });

          Object.keys(listItemObjects).forEach(key => {
            $scope.listItemObjects[key] = listItemObjects[key];
          });

          $scope.listItems = $scope.listItems.concat(listItemObjectsArray);
          $scope.lease.layers = $scope.lease.layers.concat(pageLayers);
          $scope.lease.orderedListitem = JSON.stringify($scope.listItems);
          $scope.update();
          $scope.reInsertMissingListItems();
          $scope.listsRefresh();
          $scope.restyle();
          clipboardManager.hidePastingDialog($scope);
          deferred.resolve();
          return deferred.promise;
        };
      };

      let undo = function(
        currentListItems,
        currentPageLayers,
        currentListItemUndoTracking,
        currentListItemObjects,
        $scope
      ) {
        return function() {
          const deferred = $q.defer();
          elementsToInsert.classList.remove(
            "diff-item--accepted",
            "lp-show-element"
          );
          elementsToInsert.classList.add(
            "diff-item--rejected",
            "lp-hide-element"
          );

          $scope.listItems = JSON.parse(JSON.stringify(currentListItems));
          $scope.lease.orderedListitem = JSON.stringify($scope.listItems);
          $scope.lease.restyle = JSON.parse(JSON.stringify(currentReStyle));
          $scope.listItemUndoTracking = JSON.parse(
            JSON.stringify(currentListItemUndoTracking)
          );
          $scope.listItemObjects = JSON.parse(
            JSON.stringify(currentListItemObjects)
          );
          $scope.lease.layers = currentPageLayers;
          $scope.update();
          $scope.reInsertMissingListItems();
          $scope.listsRefresh();
          deferred.resolve();

          return deferred.promise;
        };
      };

      freeTextArray = Array.from(
        elementsToInsert.querySelectorAll("[section-id]")
      );

      // freeTextArray.push(firstFreeText.closest('[section-id]'));

      // remove duplicate number from cell split in word start
      const listItemsSpacesElements = elementsToInsert.querySelectorAll(
        '[free-text] span[style*="font: 7pt"]'
      );

      // fix Bullets in table if no list items found !!!!
      const listBulletsInTables = elementsToInsert.querySelectorAll(
        'td p span[style*="7pt"]'
      );

      listBulletsInTables.forEach(el => {
        if (
          el.textContent.trim().length === 0 &&
          el.previousElementSibling &&
          el.nextElementSibling &&
          el.style.fontSize === "7pt"
        ) {
          const bulletSign = el.previousElementSibling;
          if (bulletSign.textContent.charCodeAt(0) > 10000) {
            bulletSign.innerHTML = "&#8226;";

            let pElement = el.closest("p");
            let textIndent = Math.abs(
              parseInt(window.getComputedStyle(pElement).textIndent)
            );

            if (el.nextElementSibling && el.previousElementSibling) {
              let style = el.getAttribute("style");
              style += ";display: inline-block; -aw-import: spaces;";
              style +=
                " width: " +
                (
                  textIndent -
                  el.previousElementSibling.offsetWidth -
                  2
                ).toString() +
                "px";
              el.setAttribute("style", style);
              el.innerHTML = "&nbsp;";
            }
          }
        }
      });

      listItemsSpacesElements.forEach(el => {
        if (
          el.previousElementSibling &&
          el.nextElementSibling &&
          el.previousElementSibling.textContent.trim() ==
            el.nextElementSibling.textContent.trim()
        ) {
          el.nextElementSibling.remove();
        }

        let tdElement = el.closest("td");
        if (
          tdElement &&
          el.previousElementSibling &&
          el.nextElementSibling &&
          tdElement.textContent.trim() ==
            el.previousElementSibling.textContent.trim() &&
          el.nextElementSibling.textContent.trim() == ""
        ) {
          el.nextElementSibling.remove();
        }
      });

      // fix bullets
      elementsToInsert.querySelectorAll("span").forEach(el => {
        if (
          el.children.length === 0 &&
          el.textContent.length === 1 &&
          el.textContent.charCodeAt(0) > 10000 &&
          el.textContent !== window.CONSTS.SPECIAL_CHARS.ZERO_WIDTH_NO_BREAKING_SPACE
        ) {
          el.innerHTML = "&#8226;";

          let tblElement = el.closest("table");

          if (
            el.nextSibling &&
            el.nextSibling.hasAttribute("style") &&
            el.nextSibling.getAttribute("style").indexOf("font: 7pt") !== -1
          ) {
            if (tblElement) {
              el.nextSibling.classList.add("align-list-item-space");
              el.nextSibling.style.display = "inline-block";
              el.nextSibling.classList.add("word-numbers");
            } else {
              el.nextSibling.classList.add("word-numbers");
            }
          }
        }
      });

      // remove duplicate number from cell split in word end
      // fix styles

      if (forceAllParagraph) {
        // do a restyle and remove the values

        restyleElements.forEach(item => {
          $scope.lease.restyle[item.paragraphId] = item;
        });

        $scope.restyle();

        $scope.lease.restyle = JSON.parse(JSON.stringify(currentReStyle));

        elementsToInsert
          .querySelectorAll(
            '.list-item-paragraph.inserted-list-item .word-numbers[style*="7pt"]'
          )
          .forEach(el => {
            if (el.closest("table") || el.style.fontSize !== "7pt") {
              return;
            }

            let pElement = el.closest("p");
            let textIndent = Math.abs(
              parseInt(window.getComputedStyle(pElement).textIndent)
            );

            if (el.nextElementSibling && el.previousElementSibling) {
              let style = el.getAttribute("style");
              style += ";display: inline-block; -aw-import: spaces;";
              style +=
                " width: " +
                (
                  textIndent -
                  el.previousElementSibling.offsetWidth -
                  2
                ).toString() +
                "px";
              el.setAttribute("style", style);
              el.innerHTML = "&nbsp;";
            }
          });
      }

      freeTextArray.forEach(freeText => {
        let freeTextId = parseInt(freeText.getAttribute("section-id"));
        $scope.$root.defaultProvisions[freeTextId].html = freeText.innerHTML;
        $scope.$root.defaultProvisions[
          freeTextId
        ].hashCode = LeaseEditorService.prepareForComparison(
          $scope.$root.defaultProvisions[freeTextId].html
        ).hashCode();
      });

      if (freeTextArray && freeTextArray.length !== 0) {
        let waitForSavePromises = $q.defer();
        $scope.allChangesSaved = false;
        $scope.createBulk(freeTextArray);
        $scope.$root.waitForSave(waitForSavePromises);
        await waitForSavePromises.promise;
      }

      await $scope.addToHistory({
        id: "Paste from word with list items",
        desc: "Paste from word with list items: " + this.pasteCounter,
        redo: redo(
          listItemObjectsArray,
          pageLayers,
          listItemUndoTracking,
          listItemObjects,
          $scope
        ),
        undo: undo(
          currentListItems,
          currentPageLayers,
          currentListItemUndoTracking,
          currentListItemObjects,
          $scope
        )
      });

      if (LeaseEditorService.isSelecting()) {
        // Delete the text the user selected before inserting the pasted text
        $scope.$root.deleteSelection(null, false, false, true);
      }

      $scope.histRegisterBatch(
        $scope.histStack.length - 1,
        $scope.histIndex - currentUndoIndex
      );
    };

    this.pasteWithListItems = async (
      data,
      $scope,
      $compile,
      $q,
      LeaseEditorService
    ) => {
      this.savedArguments = {
        data: data,
        $scope: $scope,
        $compile: $compile,
        $q: $q,
        LeaseEditorService: LeaseEditorService,
        forceAllParagraph: false
      };

      let currentNode;
      if ($scope.currentlyEditedNode instanceof jQuery) {
        currentNode = $scope.currentlyEditedNode.get(0);
      } else {
        currentNode = $scope.currentlyEditedNode;
      }

      let anchor = currentNode.getTopAnchor();
      const outline = anchor.getNodeOutline();

      if (leaseIfManager.isInFormManager && !outline) {
        this.showForceAllParagraphDialog(true);
      } else if (data.containsListItems) {
        this.showForceAllParagraphDialog();
      } else {
        this.continuePasteWithOutline();
      }
    };

    this.continuePasteWithListItems = () => {
      let data = this.savedArguments.data;
      let $scope = this.savedArguments.$scope;
      let $compile = this.savedArguments.$compile;
      let $q = this.savedArguments.$q;
      let forceAllParagraph = this.savedArguments.forceAllParagraph;

      let currentNode;
      if ($scope.currentlyEditedNode instanceof jQuery) {
        currentNode = $scope.currentlyEditedNode.get(0);
      } else {
        currentNode = $scope.currentlyEditedNode;
      }

      let anchor = currentNode.getTopAnchor();
      const outline = anchor.getNodeOutline();

      if (!outline && data.containsListItems && !forceAllParagraph) {
        this.showOutlinePicker($scope);
      } else {
        this.continuePasteWithOutline();
      }
    };

    this.zendesk_select_type = false;
    this.showZendesk = () => {
      zendesk.state.go("zendesk");
      this.zendesk_select_type = true;
      zendesk.sidenav.open();
    };

    this.messages = {
      Normal: `The text you are about to insert includes formatted sections. Would you like to adjust the text to match your LeasePilot template formatting?`,
      fromManagerNoOutline: `You are pasting in a location in the document which does not contain an existing outline.  
      If applicable, you can preserve the outline from your source document or create a service ticket for LeasePilot to add your language in a structured outline (recommended).`
    };

    this.showForceAllParagraphDialog = forceAllParagraph => {
      clipboardManager.hidePastingDialog(this.savedArguments.$scope);

      if (!this.forceAllParagraphDialog) {
        this.forceAllParagraphDialog = document.createElement("div");
        this.forceAllParagraphDialog.innerHTML = `<div class="outline-picker-wrapper">  
          <div class="forceAllParagraphWrapper">
            <div class="forceAllParagraph">
              <div class="title">Options</div>
              <div class="message"></div>
              <div class="announcement" style="display: none;">
                <span class="announcement__icon animate__bounce animate__animated animate__infinite">🎉</span>
                <span class="announcement__text">
                  <span>You can now adjust the font size and line spacing in the toolbar above! Look for </span>
                  <span>
                    <svg class="icon-font_size" style="max-width: 22px; max-height: 22px; padding: 2px; margin: 0 2px;">
                      <use xlink:href="#font_size"></use>
                    </svg>
                  </span>
                  <span>to change the font size and </span>
                  <span>
                    <svg class="icon-line_height" style="max-width: 22px; max-height: 22px; padding: 2px; margin: 0 2px;">
                      <use xlink:href="#line_height"></use>
                    </svg>
                  </span>
                  <span>to change the line spacing.</span>
                </span>
              </div>
              <div class="buttons">  
                <button id="btnCancel" class="btn blue-outline">Cancel</button>
                <button id="btnLeaseStyle" class="btn blue">Yes</button>
                <button id="btnContactUs" class="btn blue">Contact Support</button>
                <button id="btnOriginalStyle" class="btn blue">No</button>
              </div>
            </div>
          </div>
        </div>`;

        this.forceAllParagraphDialog
          .querySelector("#btnLeaseStyle")
          .addEventListener("click", this.continueForceAllParagraphDialog);
        this.forceAllParagraphDialog
          .querySelector("#btnOriginalStyle")
          .addEventListener("click", this.continueForceAllParagraphDialog);
        this.forceAllParagraphDialog
          .querySelector("#btnCancel")
          .addEventListener("click", this.continueForceAllParagraphDialog);

        this.forceAllParagraphDialog
          .querySelector("#btnContactUs")
          .addEventListener("click", this.continueForceAllParagraphDialog);
      }

      if (leaseIfManager.isInFormManager && forceAllParagraph) {
        this.forceAllParagraphDialog.querySelector(
          "#btnContactUs"
        ).style.display = "block";
        this.forceAllParagraphDialog.querySelector(
          ".message"
        ).textContent = this.messages.fromManagerNoOutline;
        this.forceAllParagraphDialog.querySelector(
          ".announcement"
        ).style.display = "none";
      } else {
        this.forceAllParagraphDialog.querySelector(
          "#btnContactUs"
        ).style.display = "none";
        this.forceAllParagraphDialog.querySelector(
          ".message"
        ).textContent = this.messages.Normal;
        this.forceAllParagraphDialog.querySelector(
          ".announcement"
        ).style.display = "flex";
      }

      document.body.appendChild(this.forceAllParagraphDialog);
    };

    this.continueForceAllParagraphDialog = evt => {
      let ans = evt.currentTarget.id;

      if (ans == "btnContactUs") {
        this.showZendesk();
        ans = "btnCancel";
      }

      this.forceAllParagraphDialog.remove();

      if (ans === "btnOriginalStyle") {
        this.savedArguments.forceAllParagraph = ans;
      } else if (ans === "btnCancel") {
        return;
      }

      this.continuePasteWithListItems();
    };

    this.showOutlinePicker = $scope => {
      clipboardManager.hidePastingDialog($scope);
      if (Object.keys(listItemsManager.listLevelsMap).length < 2) {
        this.continuePasteWithOutline(null);
        return;
      }
      this.listOutlineMenu = document.createElement("div");
      this.listOutlineMenu.classList.add("outline-picker-wrapper");
      let box = document.createElement("div");
      box.classList.add("box");
      this.listOutlineMenu.appendChild(box);
      let listItemMenuElement = document.createElement("list-outline-menu");
      listItemMenuElement.setAttribute("data-from-paste", true);
      box.appendChild(listItemMenuElement);
      this.savedArguments.$compile(this.listOutlineMenu)(
        this.savedArguments.$scope
      );
      document.body.appendChild(this.listOutlineMenu);
    };

    this.hideOutlinePicker = () => {
      this.listOutlineMenu.remove();
      this.listOutlineMenu = null;
    };

    this.cancelPaste = () => {
      this.hideOutlinePicker();
      clipboardManager.hidePastingDialog(scopeVar);
    };
  }

  function ClipboardData(
    type,
    text,
    rtf,
    $compile,
    $timeout,
    $scope,
    LeaseEditorService
  ) {
    this.type = type;
    this.pastedElements = null;
    this.fromWord = false;
    this.containsListItems = false;
    this.originalText = text;
    this.originalRTF = rtf;
    this.fromRTF = type === "text/rtf";
    this.fromLeasePilot = text?.indexOf("ng-scope") !== -1;

    const paragraphStylesToKeep = [
      "text-align",
      "text-indent",
      "margin-left",
      "margin-top",
      "margin-bottom",
      "font-size",
      "line-height"
    ];
    const generalStylesToKeep = [
      "font-weight",
      "font-style",
      "text-align",
      "color",
      "background-color",
      "text-decoration"
    ];
    const unsupportedForeColor = ["rgb(21, 133, 212)", "rgb(0, 0, 0)"];
    const unsupportedBackColor = [
      "rgb(227, 234, 247)",
      "rgb(160, 234, 207)",
      "rgb(253, 165, 214)",
      "rgb(255, 255, 255)",
      "rgb(253, 192, 226)",
      "rgb(255, 86, 148)",
      "rgb(169, 236, 211)",
      "rgb(46, 206, 146)"
    ];

    Object.defineProperty(this, "isOnlyText", {
      value: function() {
        return this.originalRTF.indexOf("\\par ") === -1;
      },
      writable: false
    });

    function processText(text, $compile, $timeout, $scope, LeaseEditorService) {
      this.fromWord =
        text.indexOf("<w:WordDocument>") !== -1 || this.type === "text/rtf";

      if (text.indexOf("!!SET_LEVEL") !== -1) {
        this.fromWord = true;
        this.fromRTF = true;
      }

      if (this.fromWord && !this.fromRTF) {
        text = text
          .replace(/%0A/g, "")
          .replace(/\r/g, "")
          .replace(/\n/g, " ");
      }
      const dummy = document.createElement("dummy");
      dummy.innerHTML = text;
      dummy.removeWhitespace(false);
      cleanHtmlFromRtf(dummy);
      replaceInputsFromWord(dummy);
      removeUnsupportedElements(dummy);
      moveWordStylesToInlineStyles(dummy);
      markWordListItems(dummy);
      this.markWordNumbersSection(dummy);
      updateListItemBullets(dummy);
      updateImagesControls(
        dummy,
        $compile,
        $timeout,
        $scope,
        LeaseEditorService
      );
      updatePageBreaksControls(dummy);
      updateFonts(dummy);
      // cleanHTMLFromLeasePilot(dummy);

      this.containsListItems =
        dummy.querySelector("[data-list-item-level]") !== null;

      if (text.indexOf("!!SET_LEVEL") !== -1) {
        this.containsListItems = true;
      }

      sanitizeStyle(dummy);
      sanitizeElements(dummy);

      dummy.querySelectorAll("span").forEach(span => {
        let nodeStyle = span.getAttribute("style") || "";
        if (nodeStyle.indexOf("font-weight") === -1) {
          if (span.parentElement.tagName.startsWith("H")) {
            span.setAttribute("style", nodeStyle + ";font-weight: bold;");
          } else if (span.parentElement.tagName.startsWith("P")) {
            span.setAttribute("style", nodeStyle + ";font-weight: normal;");
          }
          if (span.parentElement.style.fontWeight !== "") {
            span.style.fontWeight = span.parentElement.style.fontWeight;
          }
        }
      });

      this.pastedElements = dummy;
    }
    processText = processText.bind(this);

    // function cleanHTMLFromLeasePilot(dummy) {
    //   if (!this.fromLeasePilot) {
    //     return;
    //   }
    //   dummy.querySelectorAll('[data-list-set-level]').forEach((node)=>{
    //     let level = node.getAttribute('data-list-set-level').
    //       replace('SET_LEVEL_','');
    //       node.setAttribute('data-list-item-level',level);
    //   });
    // }
    // cleanHTMLFromLeasePilot = cleanHTMLFromLeasePilot.bind(this);
    function updatePageBreaksControls(dummy) {
      let brs = dummy.querySelectorAll("br");
      brs.forEach(br => {
        br.classList.add("new-page-break");
      });
    }

    function updateFonts(dummy) {
      const fontFamily = document.querySelector("[free-text] .PLACEHOLDER")
        .style.fontFamily;

      dummy.querySelectorAll("span").forEach(span => {
        span.style.fontFamily = fontFamily;
      });
    }

    this.updateFonts = updateFonts;

    function updateImagesControls(
      dummy,
      $compile,
      $timeout,
      $scope,
      LeaseEditorService
    ) {
      let images = dummy.querySelectorAll("img");
      images.forEach(function(img) {
        var imageMenu = img.parentElement.querySelector("image-controls");
        let imageParagraph = img.parentElement;
        img.setAttribute("data-original-src", img.getAttribute("src"));
        if (!imageMenu) {
          if (img.width > imageParagraph.offsetWidth) {
            let newSrc = LeaseEditorService.resizeBase64Image(
              img.src,
              img.width,
              img.height,
              null
            );
            img.src = newSrc;
            img.setAttribute("data-original-src", newSrc);
          }
          imageMenu = document.createElement("image-controls");
          img.parentElement.prepend(imageMenu);
          let paragraph = img.closest("p");
          paragraph.classList.add("image-container");
        }
        var size = img.getAttribute("data-size");
        if (size) {
          $timeout(function() {
            // wait for compile to finish, so we have the button
            if (size !== "original") {
              imageMenu.querySelector(".selected").classList.remove("selected");
            }

            imageMenu
              .querySelector(`[data-size="${size}"]`)
              .classList.add("selected");
          });
        }
      });
    }

    function replaceInputsFromWord(dummy) {
      if (!(this.fromWord && this.fromRTF)) {
        return;
      }
      let inputs = dummy.querySelectorAll("input");
      inputs.forEach(inputNode => {
        let spanNode = document.createElement("span");
        spanNode.innerText = inputNode.value;
        inputNode.parentElement.insertBefore(spanNode, inputNode);
        inputNode.remove();
      });
    }
    replaceInputsFromWord = replaceInputsFromWord.bind(this);

    function updateListItemBullets(dummy) {
      if (!(this.fromWord && this.fromRTF)) {
        return;
      }
      dummy.querySelectorAll('[data-list-item-level="0"]').forEach(node => {
        if (node.innerHTML.indexOf("") !== -1) {
          const ul = document.createElement("ul");
          ul.style.margin = "0px";
          const li = document.createElement("li");
          ul.appendChild(li);
          node.removeAttribute("data-list-item-level");
          node.setAttribute("data-is-bullet", true);
          while (node.childNodes.length !== 0) {
            if (node.childNodes[0].innerHTML.indexOf("") !== -1) {
              node.childNodes[0].remove();
            } else {
              li.appendChild(node.childNodes[0]);
            }
          }
          node.appendChild(ul);
        }
      });
      let index = 0;
      while (index < dummy.childNodes.length) {
        let node = dummy.childNodes[index];
        if (node.hasAttribute("data-is-bullet")) {
          while (
            node.nextElementSibling &&
            node.nextElementSibling.hasAttribute("data-is-bullet")
          ) {
            node
              .querySelector("ul")
              .appendChild(node.nextElementSibling.querySelector("li"));
            node.nextElementSibling.remove();
          }
        }
        index++;
      }
    }
    updateListItemBullets = updateListItemBullets.bind(this);

    function cleanHtmlFromRtf(dummy) {
      if (!(this.fromWord && this.fromRTF)) {
        return;
      }

      const listItemLevelInfoSelector =
        '[style="display:none;user-select:none;"]';
      dummy.querySelectorAll(listItemLevelInfoSelector).forEach(node => {
        const levelText = node.innerText
          .replace(/!!/g, "")
          .replace(/SET_LEVEL_/, "");
        const pElement = node.closest("p");
        const tableElement = node.closest("table");

        let spacesElement;
        if (
          pElement.nextElementSibling &&
          pElement.nextElementSibling.innerHTML.indexOf("") !== -1
        ) {
          pElement.remove();
        } else if (tableElement) {
          tableElement.setAttribute("data-list-item-level", levelText);
          spacesElement = tableElement.querySelector(
            `[style="font:7pt 'Times New Roman'; -aw-import:spaces"]`
          );
          pElement.remove();
        } else {
          if (pElement.nextElementSibling) {
            pElement.nextElementSibling.setAttribute(
              "data-list-item-level",
              levelText
            );
            spacesElement = pElement.nextElementSibling.querySelector(
              `[style="font:7pt 'Times New Roman'; -aw-import:spaces"]`
            );
          }
          pElement.remove();
        }
        if (spacesElement) {
          spacesElement.classList.add("word-numbers");

          if (spacesElement.previousElementSibling) {
            spacesElement.previousElementSibling.classList.add("word-numbers");
          }
        }
      });

      dummy.querySelectorAll("[data-list-item-level]").forEach(node => {
        if (!node.querySelector(".word-numbers")) {
          if (
            node.firstElementChild &&
            node.firstElementChild.tagName &&
            node.firstElementChild.tagName === "SPAN" &&
            node.children.length > 1
          ) {
            node.firstElementChild.classList.add("word-numbers");
          }
        }
      });

      dummy.querySelectorAll("del").forEach(el => {
        el.remove();
      });

      dummy.querySelectorAll("ins").forEach(ins => {
        while (ins.children.length !== 0) {
          ins.parentElement.insertBefore(ins.children[0], ins);
        }
        ins.remove();
      });
    }
    cleanHtmlFromRtf = cleanHtmlFromRtf.bind(this);

    this.cleanHtmlFromRtf = cleanHtmlFromRtf;

    function sanitizeElements(dummy) {
      if (!this.fromRTF) {
        let text = dummy.innerHTML;

        var iterator = text.matchAll(/<\/\w*>([ ]+)</g);
        var dummyHtml = text;
        let v = iterator.next();

        while (!v.done) {
          let rep = v.value[0].replace(v.value[1], "");
          dummyHtml = dummyHtml.replace(v.value[0], rep);
          v = iterator.next();
        }

        text = dummyHtml;

        text = sanitizeHtml(text, {
          allowedTags: [
            "b",
            "i",
            "u",
            "p",
            "span",
            "br",
            "input",
            "font",
            "sup",
            "sub",
            "p",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            "table",
            "tr",
            "td",
            "th",
            "img",
            "a"
          ],
          transformTags: {
            a: function(tagName, attribs) {
              return {
                tagName: "span"
              };
            },
            strong: function(tagName, attribs) {
              return {
                tagName: "span",
                attribs: {
                  style: "font-weight: bold;"
                }
              };
            },
            b: function(tagName, attribs) {
              return {
                tagName: "span",
                attribs: {
                  style: "font-weight: bold;"
                }
              };
            },
            em: function(tagName, attribs) {
              return {
                tagName: "span",
                attribs: {
                  style: "font-style: italic;"
                }
              };
            },
            i: function(tagName, attribs) {
              return {
                tagName: "span",
                attribs: {
                  style: "font-style: italic;"
                }
              };
            },
            u: function(tagName, attribs) {
              return {
                tagName: "span",
                attribs: {
                  style: "text-decoration: underline;"
                }
              };
            }
          },
          allowedAttributes: {
            "*": ["style", "data-*"],
            input: ["type", "value"],
            p: ["data-*", "class"],
            br: ["style"]
          },
          allowedClasses: {
            p: ["new-paragraph", "word-numbers"],
            "*": ["word-numbers"]
          },
          selfClosing: ["br"]
        });
        text = text.replace(/<br \/>/g, "<br />&#xFEFF;");
        dummy.innerHTML = text;
      }
    }
    sanitizeElements = sanitizeElements.bind(this);

    function sanitizeStyle(dummy) {
      if (!this.fromRTF) {
        dummy.querySelectorAll("[style]").forEach(node => {
          let stylesToKeep;
          let savedStyles = "";
          let foundStye = false;
          if (node.tagName === "P") {
            stylesToKeep = [...paragraphStylesToKeep, ...generalStylesToKeep];
          } else {
            stylesToKeep = generalStylesToKeep;
          }
          if (
            node.tagName === "TABLE" ||
            node.tagName === "THEAD" ||
            node.tagName === "TBODY" ||
            node.tagName === "TR" ||
            node.tagName === "TD" ||
            node.tagName === "TH" ||
            node.tagName === "BR"
          ) {
            return;
          }

          stylesToKeep.forEach(key => {
            if (node.style[key] && node.style[key] !== "") {
              if (key === "color") {
                if (unsupportedForeColor.indexOf(node.style[key]) === -1) {
                  savedStyles += `${key}: ${node.style[key]};`;
                  foundStye = true;
                }
              } else if (key === "background-color") {
                if (unsupportedBackColor.indexOf(node.style[key]) === -1) {
                  savedStyles += `${key}: ${node.style[key]};`;
                  foundStye = true;
                }
              } else {
                savedStyles += `${key}: ${node.style[key]};`;
                foundStye = true;
              }
            }
          });

          if (foundStye) {
            node.setAttribute("style", savedStyles);
          } else {
            node.removeAttribute("style");
          }
        });
      }
    }
    sanitizeStyle = sanitizeStyle.bind(this);

    function moveWordStylesToInlineStyles(dummy) {
      if (this.fromWord && !this.fromRTF) {
        let displayValue = dummy.style.display;
        dummy.style.display = "none";
        document.body.appendChild(dummy);
        dummy.querySelectorAll("*").forEach(node => {
          let nodeStyle = window.getComputedStyle(node);
          generalStylesToKeep.forEach(key => {
            node.style[key] = nodeStyle[key];
          });
        });
        dummy.remove();
        dummy.querySelectorAll("style").forEach(styleElement => {
          styleElement.remove();
        });
        dummy.style.display = displayValue;
      }
    }
    moveWordStylesToInlineStyles = moveWordStylesToInlineStyles.bind(this);

    function removeUnsupportedElements(dummy) {
      if (this.fromWord && !this.fromRTF) {
        const unsupportedElementFromWord = ["META", "LINK"];
        let scanIndex = 0;
        let node = null;

        dummy.querySelectorAll("del").forEach(node => {
          node.remove();
        });

        while (scanIndex < dummy.childNodes.length) {
          node = dummy.childNodes[scanIndex];
          if (
            node.nodeType === Node.TEXT_NODE ||
            node.nodeType === Node.COMMENT_NODE
          ) {
            node.remove();
          } else if (
            node.tagName &&
            unsupportedElementFromWord.indexOf(node.tagName) !== -1
          ) {
            node.remove();
          } else {
            scanIndex++;
          }
        }
      }
    }
    removeUnsupportedElements = removeUnsupportedElements.bind(this);

    function markWordListItems(dummy) {
      if (this.fromWord && !this.fromRTF) {
        let foundListItems = false;
        const getLevelRegex = new RegExp("mso-list:.*level(.*) ");
        dummy.querySelectorAll('[style*="mso-list"]').forEach(node => {
          const getLevel = getLevelRegex.exec(node.getAttribute("style"));
          if (getLevel && getLevel[1] && parseInt(getLevel[1])) {
            // word start lists levels from 1 and we start form 0 thats why we do:`getLevel[1]) - 1`
            node.setAttribute(
              "data-list-item-level",
              parseInt(getLevel[1]) - 1
            );
            foundListItems = true;
          }
        });

        dummy.childNodes.forEach(node => {
          if (
            node &&
            node.querySelector &&
            node.querySelector("[data-list-item-level]")
          ) {
            node.setAttribute(
              "data-list-item-level",
              node
                .querySelector("[data-list-item-level]")
                .getAttribute("data-list-item-level")
            );
          }
        });

        return foundListItems;
      }
    }
    markWordListItems = markWordListItems.bind(this);

    this.markWordNumbersSection = element => {
      if (this.fromWord && !this.fromRTF) {
        let inNumberSection = false;
        let index = 0;
        let node;
        let numbersSpan = document.createElement("span");
        numbersSpan.classList.add("word-numbers");
        while (index < element.childNodes.length) {
          node = element.childNodes[index];
          if (node !== numbersSpan) {
            if (node.nodeType === Node.COMMENT_NODE) {
              if (node.textContent.indexOf("supportLists") !== -1) {
                inNumberSection = true;
                node.parentElement.insertBefore(numbersSpan, node);
                node.remove();
              } else if (node.textContent.indexOf("endif") !== -1) {
                inNumberSection = false;
                node.remove();
              } else {
                node.remove();
              }
            } else if (inNumberSection) {
              numbersSpan.appendChild(node);
            } else {
              index++;
            }

            if (
              node.childNodes &&
              node.childNodes.length !== 0 &&
              !inNumberSection
            ) {
              this.markWordNumbersSection(node);
            }
          } else {
            index++;
          }
        }
      }
    };

    try {
      processText(text, $compile, $timeout, $scope, LeaseEditorService);
    } catch (e) {
      this.processTextFailed = true;
    }
  }

  window.clipboardManager = new ClipboardManager();
})();

// window.clipboardHTML
// window.testTingHtmlFromRTF ;
